import React, { useState } from "react";
import { useIntl } from "react-intl";

import Close from "./close";

const ServiceModal = ({ node, children }) => {
  const intl = useIntl();
  const [modal, setModal] = useState("");

  const {
    url,
    details: {
      image: { width, height },
    },
  } = node.file;

  function toggleModal() {
    setModal(modal === "" ? " active" : "");
  }

  return (
    <>
      <button onClick={toggleModal}>{children}</button>
      <div className={`modal modal-service${modal}`}>
        <div className="modal-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6">
                <div className="modal-service-grid">
                  <button className="modal-close" onClick={toggleModal} aria-label="Close Modal">
                    <Close />
                  </button>
                  <div className="modal-service-grid-image">
                    <img
                      src={url}
                      width={width}
                      height={height}
                      alt={node.title}
                    />
                  </div>
                  <div className="modal-service-grid-text">
                    <h3>{node.title}</h3>
                    <p>{node.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="modal-backdrop"
          aria-label={intl.formatMessage({ id: "close" })}
          onClick={toggleModal}
        ></button>
      </div>
    </>
  );
};

export default ServiceModal;
