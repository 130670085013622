import React, { useContext } from "react";
import { useIntl } from "react-intl";
import Img from "gatsby-image";

import Context from "./context";
import ServiceModal from "./service-modal";

const ServicesGrid = () => {
  const { data } = useContext(Context);
  const intl = useIntl();

  return (
    <div className="row">
      <div className="col-lg-4 pr-lg-10 text-center text-lg-left">
        <h2 className="mb-md-10">
          {intl.formatMessage({ id: "index.services.heading" })}
        </h2>
        <div className="tabs tabs-images">
          <ul className="tabs-list">
            <li className="tabs-list-item">
              {data.contentfulEsprit.logo && (
                <Img
                  className="tabs-list-item-image"
                  fluid={data.contentfulEsprit.logo.fluid}
                  alt={data.contentfulEsprit.title}
                />
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-8 mt-10 mt-lg-0 modal-services-scroll">
        {data.contentfulEsprit.icon_gallery && (
          <div className="services services-full">
            {data.contentfulEsprit.icon_gallery.map((node, i) => {
              const {
                url,
                details: {
                  image: { width, height },
                },
              } = node.file;

              return (
                <div className="services-item" key={i}>
                  <ServiceModal node={node}>
                    <img
                      src={url}
                      width={width}
                      height={height}
                      alt={node.title}
                    />
                    <p>
                      <span>{node.title}</span>
                    </p>
                  </ServiceModal>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicesGrid;
