import React, { useContext } from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";
import Img from "gatsby-image";
import Slider from "react-slick";

import Context from "./context";

const ServicesSlider = () => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const allPages = data.allContentfulPage.nodes;

  const settings = {
    infinite: false,
    slidesToShow: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  function getParentSlug() {
    return allPages.find((page) => page.category === "Services").slug;
  }

  return (
    <Slider className="slider slider-thumbnails" {...settings}>
      {data.allContentfulService.nodes.map((node, i) => (
        <Link
          className="slider-thumbnails-item"
          to={`/${intl.locale}/${getParentSlug()}/${node.slug}/`}
          key={i}
        >
          {node.thumbnail && (
            <Img
              className="slider-thumbnails-item-image"
              fluid={node.thumbnail.fluid}
              alt={node.title}
            />
          )}
          <h3>{node.title}</h3>
        </Link>
      ))}
    </Slider>
  );
};

export default ServicesSlider;
